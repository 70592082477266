.certificates-view {
    margin-bottom: 50px;
}

.certificates-view > h2 {
    text-align: center;
    color: var(--pink);
    font-size: 35px;
    margin: 20px 0 15px;
}

.certificates-view .certificates {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.certificates-view .certificates img {
    user-select: none;
    border-radius: var(--borderRadius);
    max-width: 100%;
    width: 250px;
    border: rgb(255, 255, 255, .3) 3px solid;
}

.certificates-view h3 {
    font-size: 35px;
    text-align: center;
    margin: 20px 10px;
    color: var(--contrColor);
    overflow: hidden;
}