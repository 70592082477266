@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

body, html {
    overscroll-behavior: none;
}

body {
    margin: 0;
    font-family: Mulish, serif;
    background: var(--bgLight);
    --borderRadius: 15px;

    --pink: #ce4a86;
    /* light-dark-mode color-accent pink */
    --darkDarkPink: #4E3D45;
    --darkLightPink: #605258;
    --lightDarkPink: #FFF0F7;
    --lightLightPink: #FFE9EE;

    --lifeCoachColor: var(--pink);
    --consultationsColor: #217ACC;
    --relaxColor: #45A29A;
    --womenCircleColor: #D96B3A;

    --bgLight: #494748;
    --bgDark: #333333;

    --textColor: #CEABBD;
    --contrColor: white;

    --iconBgColor: white;
}

@media (prefers-color-scheme: light) {

    body {
        --bgLight: #fff5f4;
        --bgDark: white;

        --contrColor: black;
        --textColor: var(--contrColor);
    }

    .only-dark {
        display: none;
    }

}

@media (prefers-color-scheme: dark) {

    .only-light {
        display: none;
    }

}

.loader {
    border: var(--pink) solid 4px;
    border-bottom-color: transparent;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    animation: spin 1s ease-in-out infinite;
}

.loader.big {
    border-width: 6px;
    width: 35px;
    height: 35px;
}

.loader.margin {
    margin: 20px;
}

.loader.center {
    margin-left: auto;
    margin-right: auto;
}