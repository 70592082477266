.story-view {
    margin-bottom: 50px;
}

.story-view > h2 {
    text-align: center;
    color: var(--pink);
    font-size: 35px;
    margin: 20px 0 15px;
}

.story-view p {
    width: calc(100% - 20px);
    max-width: 800px;
    margin: 10px auto;
    text-align: center;
    color: var(--textColor);
}