.contact-view {
    margin-bottom: 50px;
}

.contact-view > h2 {
    text-align: center;
    color: var(--pink);
    font-size: 35px;
    margin: 20px 0 15px;
}

.contact-view .contact-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    gap: 50px;
}

.contact-view .contact-list > div {
    flex-grow: 1;
    max-width: 300px;
    width: 50%;
    text-align: center;
}

.contact-view .contact-list .name {
    font-weight: bold;
    color: var(--textColor);
}

.contact-view .contact-list .name .logo {
    font-size: 20px;
    color: var(--pink);
}

.contact-view .contact-list .name p {
    margin: 15px 0 0;
}

.contact-view .contact-list .name .logo svg {
    vertical-align: middle;
    height: 20px;
}

.contact-view .contact-list .name .tagline {
    margin-top: 5px;
    color: var(--pink);
    font-weight: normal;
}

.contact-view .contact-list .contacts {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contact-view .contact-list .contacts a {
    margin: 0;
    color: var(--textColor) !important;
    text-decoration: none !important;
}

.contact-view .contact-list .contacts a svg {
    vertical-align: middle;
    margin-right: 5px;
}