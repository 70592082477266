.button {
    display: inline-block;
    background: var(--pink);
    color: white;
    text-decoration: none;
    padding: 10px 25px;
    border-radius: 20px;
    font-size: 18px;
    margin: 25px 0 0;
    user-select: none;
    transition: background 100ms;
}

.button:hover {
    background: #b53871;
}