.section.summary {
    display: block;
}

.section.summary .items {
    width: inherit;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 30px 0;
    
    user-select: none;
}

.item {
    margin: 0 30px;
    cursor: pointer;
}

.item.lifecoach {
    color: var(--lifeCoachColor);
}

.item.consultations {
    color: var(--consultationsColor);
}

.item.relax {
    color: var(--relaxColor);
}

.item.womencircle {
    color: var(--womenCircleColor);
}

.item {
    text-align: center;
}

.item svg {
    height: 40px;
}

.item p {
    margin: 8px 0 0;
    color: var(--textColor);
    font-size: 20px;
}


.section.summary .summary-title {
    font-size: 35px;
    text-align: center;
    margin: 60px 20px;
    color: var(--contrColor);
}

@media screen and (max-width: 650px) {
    
    .item {
        flex: 0;
    }
    
    .items {
        justify-content: space-around;
        gap: 50px;
    }

    .section.summary .summary-title {
        display: none;
    }
    
}