.section.womencircle {
    gap: 10%;
}

.section.womencircle .photo-container {
    flex-grow: .6;
}

.section.womencircle .background-icon {
    bottom: 0;
    right: -90%;
    height: calc(75%);
    --iconBgColor: var(--backgroundColor);
}