header {
    --height: 40px;
    height: var(--height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    --paddingTop: 6px;
    padding: var(--paddingTop) 40px;
    background-color: var(--bgDark);

    border-bottom-left-radius: var(--borderRadius);
    border-bottom-right-radius: var(--borderRadius);

    user-select: none;
}

header .logo {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
}

header .logo svg {
    height: calc(var(--height) - 2 * var(--paddingTop));
}

header .logo h1.name {
    color: var(--pink);
    font-size: 22px;
    font-weight: bold;
    padding-left: 15px;
    transform: translateY(-2px);
}

header .logo h2.name {
    padding-left: 30px;
    font-size: 15px;
    font-weight: normal;
    color: var(--textColor);
}

header .nav {
    display: inline-flex;
    align-items: center;
}

header .nav a {
    display: inline;
    text-decoration: none;
    color: var(--textColor);
    margin-left: 60px;
    font-size: 15px;
    text-align: center;
}

header .nav a:first-child {
    margin-left: 0;
}

header .nav a.space {
    margin-left: 120px;
}

@media screen and (max-width: 900px) {
    header .nav a {
        margin: 0 !important;
    }

    header .nav {
        gap: 20px;
    }
}

@media screen and (max-width: 650px) {
    header .logo h2 {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    header .logo h1 {
        display: none;
    }

    header {
        padding-left: 20px;
        padding-right: 20px;
    }
}