.rate-view {
    text-align: center;
}

.rate-view > h2 {
    color: var(--textColor);
    font-size: 35px;
    margin: 20px 0 15px;
}

.rate-view .stars {
    display: flex;
    justify-content: center;
    gap: 5px;
}

.rate-view .stars svg {
    color: var(--pink);
    cursor: pointer;
}

.rate-view .stars svg:not(.active) {
    opacity: .25;
}

.rate-view textarea {
    --paddingSide: 10px;
    width: 300px;
    display: block;
    max-width: calc(100% - var(--paddingSide) * 2);
    padding: 8px var(--paddingSide);
    resize: none;
    border: 0;
    outline: none;
    background: rgb(255, 255, 255, .6);
    margin: 20px auto;
    font-size: 1.2em;
    border-radius: var(--borderRadius);
}

.rate-view > p {
    color: var(--textColor);
    font-size: 1.1em;
    margin: 10px 0;
}

.rate-view .button {
    margin-top: 0;
}