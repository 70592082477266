.refrences {
    max-width: unset;
    cursor: grab;
}

.refrences.grabbing {
    cursor: grabbing;
}

.refrences .content {
    position: relative;
    min-height: 400px;
    overflow-y: hidden;
    overflow-x: auto;
}

.refrences .content .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    z-index: -1;
    filter: opacity(.5);
    user-select: none;
}

.refrences .content .ratings {
    display: flex;
    align-items: stretch;
    gap: 40px;
    padding: 0 40px;
    max-width: 100%;
    min-height: inherit;
    overflow-x: auto;
}

.refrences h3 {
    font-size: 35px;
    text-align: center;
    margin: 60px 20px;
    color: var(--contrColor);
    overflow: hidden;
}

@media (prefers-color-scheme: dark) {

    .refrences .content .background {
        filter: blur(4px) opacity(.5);
    }

}

@media screen and (min-width: 900px) {

    .refrences .content .ratings {
        gap: 150px;
        padding: 0 150px;
    }

}