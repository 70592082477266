.section.info {
    flex-direction: column;
    justify-content: flex-start;
    height: 300px;
    position: relative;
    overflow: hidden;
}

.section.info > * {
    margin: 0 0 0 70px;
}

.section.info h2 {
    color: var(--contrColor);
    font-size: 60px;
    margin-top: 50px;
}

.section.info h3 {
    color: var(--pink);
    font-size: 35px;
}

.section.info .icon {
    position: absolute;
    height: calc(100% + 40px);
    right: -40px;
    top: -3px;

    user-select: none;
}

@media screen and (max-width: 900px) {

    .section.info h2 {
        margin-top: 0;
    }

    .section.info .icon {
        top: unset;
        bottom: calc(-100% + 150px);
        right: unset;
        left: 50%;
        transform: translateX(-50%);
    }

    .section.info > * {
        margin: 0 10px;
        text-align: center;
    }

}

@media screen and (max-width: 500px) {

    .section.info .icon {
        display: none;
    }

    .section.info {
        justify-content: center;
    }

}