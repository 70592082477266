.section {
    --backgroundColor: var(--bgLight);
    margin-top: 50px;
    display: flex;
    width: 100%;
    padding: 20px 0;
    --bgIconColor: var(--darkLightPink);
}

.section:nth-child(odd) {
    --backgroundColor: var(--bgDark);
    background: var(--backgroundColor);
    border-radius: var(--borderRadius);
    --bgIconColor: var(--darkDarkPink);
}

@media (prefers-color-scheme: light) {

    .section {
        --bgIconColor: var(--lightLightPink);
    }

    .section:nth-child(odd) {
        --bgIconColor: var(--lightDarkPink);
    }

}

.section.type {
    align-items: center;
    --padding: 70px;
    padding: var(--padding) 0;
    gap: 20px;
}

.section.type:nth-child(even) {
    flex-direction: row-reverse;
}

.section.type > * {
    flex: 1;
}

.section.type .photo-container {
    position: relative;
    flex-grow: 1.4;
    width: 70%;
    margin: 0 var(--padding) 0 0;
}

.section.type:nth-child(even) .photo-container {
    margin: 0 0 0 var(--padding);
}

.section.type .photo {
    position: relative;
    z-index: 1;
    width: 100%;
    user-select: none;
}

.section.type .background-icon {
    color: var(--bgIconColor);
    position: absolute;
}

.section.type .text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;

    position: relative;
    z-index: 1;
    margin: 0 0 0 var(--padding);
    color: var(--textColor);
}

.section.type:nth-child(even) .text {
    margin: 0 var(--padding) 0 0;
}

.section.type .text h2 {
    color: var(--contrColor);
    margin: 30px 0 15px;
    font-size: 40px;
}

.section.type .text p {
    margin: 0;
    font-size: 20px;
    font-weight: lighter;
}

.section.type:nth-child(even) .text {
    text-align: right;
}

.section.type .title-icon {
    color: var(--pink);
}

@media screen and (max-width: 900px) {

    .section.type .photo-container {
        display: none;
    }

    .section.type .text {
        margin: 0 20px !important;
        text-align: center !important;
    }

}