.document {
    text-align: center;
    color: var(--textColor);
}

.document :is(h2, h3) {
    margin: 0;
    font-weight: bold;
    color: var(--pink);
}

.document h2 {
    font-size: 35px;
    margin: 25px 0;
}

.document h3 {
    font-size: 25px;
    margin: 20px 0 10px;
}

.document ol {
    padding: 0;
    margin: 0;
    list-style: none;
}

.document li {
    margin-top: 10px;
}

.document li::before {
    content: counter(list-item) ". ";
    color: var(--pink);
    display: inline;
}

.document ol[type="A"] li {
    margin-top: 5px;
}

.document ol[type="A"] li::before {
    content: counter(list-item, lower-alpha) ") ";
}