.reference-entry {
    display: flex;
    flex-direction: column;
    user-select: none;
    pointer-events: none;
    width: 300px;
    flex-shrink: 0;
    margin: 40px 0;
    height: inherit;
    background: var(--bgDark);
    border-radius: var(--borderRadius);
    overflow: auto;
}

.reference-entry .name {
    margin: 0;
    padding: 10px;
    font-size: 1.1em;
    text-align: center;
    font-weight: bold;
    color: white;
    background: var(--pink);
}

.reference-entry .stars {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin: 15px 0;
}

.reference-entry .stars svg {
    color: var(--pink);
}

.reference-entry .stars svg:not(.active) {
    opacity: .25;
}

.reference-entry .text {
    margin: 10px 25px;
    flex: 1;
}

.reference-entry .date {
    margin: 0 25px 20px;
}

.reference-entry :is(.text, .date) {
    color: var(--textColor);
}