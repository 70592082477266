.pricing-view {
    margin-bottom: 50px;
}

.pricing-view > h2 {
    text-align: center;
    color: var(--pink);
    font-size: 35px;
    margin: 20px 0 15px;
}

.pricing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    justify-items: stretch;
}

.pricing-entry {
    display: flex;
    gap: 20px;
    align-items: center;
    width: 300px;
}

.pricing-entry * {
    margin: 0;
    width: 100%;
}

.pricing-entry p {
    color: var(--darkLightPink);
}

.pricing-entry svg {
    width: 100px;
    color: var(--pink);
}

@media (prefers-color-scheme: dark) {

    .pricing-entry h2 {
        color: var(--pink);
    }

    .pricing-entry p {
        color: var(--lightLightPink);
    }

}